<template>
  <ui-component-modal
    modalTitle="Edit description"
    modalSize="large"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="saveSpaceText"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div class="columns is-multiline">
        <div
          v-for="(textLanguage, index) in mDescriptions"
          :key="'column' + index"
          class="column is-half"
        >
          <p>{{ $t('LanguageLabels.Language' + textLanguage.LanguageId) }}</p>
          <div class="field">
            <div class="control">
              <input
                class="input"
                type="text"
                placeholder="Space name"
                v-model="textLanguage.Name"
              />
            </div>
          </div>
          <div class="field">
            <div class="control">
              <ui-text-editor
                :id="'editor' + index"
                v-model="textLanguage.Description"
              ></ui-text-editor>
            </div>
          </div>
        </div>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import spaceProvider from '@/providers/space'

export default {
  components: {},

  props: {
    meetingtypeId: {
      default: 0,
      type: Number,
    },

    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      mDescriptions: [],
      mSpace: null,
    }
  },

  computed: {
    ...mapState('spaceStore', ['space']),

    descriptions() {
      if (this.space) {
        return this.space.Descriptions.filter(
          (s) => s.MeetingtypeId === this.meetingtypeId
        )
      }
      return []
    },
  },

  created() {
    this.mDescriptions = JSON.parse(JSON.stringify(this.descriptions))
    this.mSpace = JSON.parse(JSON.stringify(this.space))
  },

  methods: {
    ...mapMutations('spaceStore', ['setSpace']),

    saveSpaceText() {
      let self = this
      this.mSpace.Descriptions = self.mDescriptions

      if (!self.isSaving) {
        self.isSaving = true

        spaceProvider.methods
          .updateSpace(self.mSpace)
          .then((response) => {
            if (response.status === 200) {
              self.isSavingSuccess = true
              self.setSpace(response.data)

              let t = setTimeout(() => {
                self.onClickCancel()
                clearTimeout(t)
              }, 1500)
            }
          })
          .catch((e) => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>
